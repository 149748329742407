import React from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const animations = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 }
};

function AnimatedPage({ children }) {
  const location = useLocation();
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 1 }}
      location={location}
      key={location.key}
    >
      {children}
    </motion.div>
  );
}

export default AnimatedPage;
