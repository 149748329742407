import { useContext } from "react";
import { LanguageContext } from "./LanguageProvider";
const initialState = {
  selectedLang: "tr"
};

export function getLanguageConfig() {
  const ls = localStorage.getItem("languageConfig");
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

export function useLang() {
  return useContext(LanguageContext).selectedLang;
}

export function setLanguage(lang) {
  localStorage.setItem("languageConfig", JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}
