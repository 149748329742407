import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const livexamyQuestionPoolSlice = createSlice({
  name: "livexamyQuestionPool",
  initialState,
  reducers: {
    updateLivexamyQuestionPool: (state, action) => {
      return [...action.payload];
    }
  }
});
export default livexamyQuestionPoolSlice.reducer;

export const { updateLivexamyQuestionPool } = livexamyQuestionPoolSlice.actions;
