const SchoolTypeEnumColor = {
  PRIMARY: "success",
  KREŞ: "danger",
  ANAOKULU: "primary",
  ILKOKUL: "warning",
  ORTAOKUL: "info",
  ANADOLU_LISESI: "success",
  FEN_LISESI: "",
  KURS: ""
};

const SchoolTypeEnum = {
  PRIMARY: "PRIMARY",
  KREŞ: "KREŞ",
  ANAOKULU: "ANAOKULU",
  ILKOKUL: "ILKOKUL",
  ORTAOKUL: "ORTAOKUL",
  ANADOLU_LISESI: "ANADOLU_LISESI",
  FEN_LISESI: "FEN_LISESI",
  KURS: "KURS"
};

const SchoolTypeNameEnum = {
  PRIMARY: "Okul Öncesi",
  KREŞ: "Kreş",
  ANAOKULU: "Anaokul",
  ILKOKUL: "İlkokul",
  ORTAOKUL: "Ortaokul",
  ANADOLU_LISESI: "Anadolu Lisesi",
  FEN_LISESI: "Fen Lisesi",
  KURS: "Kurs"
};

const SchoolTypeArray = Object.keys(SchoolTypeEnum).map((item, index) => ({
  id: index,
  name: item
}));

const SchoolTypeEnumArray = Object.keys(SchoolTypeEnum).map((item) => ({
  id: item,
  name: SchoolTypeNameEnum[item]
}));

const InstitutionTypeList = [
  { id: 1, label: "Kolej", value: "KOLEJ" },
  { id: 2, label: "Bağımsız Anadolu Lisesi", value: "BANDLIS" },
  { id: 3, label: "Sınav Okulları", value: "SINAV_OKULLARI" },
  { id: 4, label: "Kurs", value: "KURS" }
];

Object.freeze(SchoolTypeEnum);
Object.freeze(SchoolTypeArray);
Object.freeze(InstitutionTypeList);

export {
  SchoolTypeEnum,
  SchoolTypeArray,
  SchoolTypeEnumArray,
  SchoolTypeEnumColor,
  SchoolTypeNameEnum,
  InstitutionTypeList
};
