const RoleEnum = {
  ADMIN: "ADMIN",
  STUDENT: "STUDENT",
  CONSERVATOR: "CONSERVATOR"
};

const RoleTypeEnum = {
  ADMIN: "ADMIN",
  PERSONNEL: "PERSONNEL",
  STUDENT: "STUDENT",
  CONSERVATOR: "CONSERVATOR",
  CONTENT_PRODUCER: "CONTENT_PRODUCER"
};

Object.freeze(RoleEnum);
Object.freeze(RoleTypeEnum);

export { RoleEnum, RoleTypeEnum };
