import ErrorMessage from "components/Animations/ErrorMessage";
import React from "react";
import { FormattedMessage } from "react-intl";

function ErrorBoundaryMessage() {
  return (
    <div className="row" style={{ backgroundColor: "#EEF0F7", height: "100vh", width: "100%" }}>
      <div
        className="container my-auto flex-xs-middle bg-white"
        style={{
          background: "white",
          boxShadow: "none",
          border: "2px solid #e7e9ff",
          borderRadius: "8px"
        }}
      >
        <ErrorMessage />
        <h1 className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15 text-center">
          <p className="font-size-h3 font-weight-light">
            <FormattedMessage id="SOMETHING.WENT.WRONG" />
          </p>
          <a
            onClick={() => window.location.reload()}
            className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 lms-text-elipsis"
          >
            <FormattedMessage id="TRAY.AGAIN" />
          </a>
        </h1>
      </div>
    </div>
  );
}

export default ErrorBoundaryMessage;
