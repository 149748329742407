import React from "react";

function EmptyList({
  title = "Henüz bilgi yok!",
  message = "Görünen o ki, bu alana henüz bilgi girişi olmamış.",
  src = false,
  showImage = true
}) {
  return (
    <div className="w-100 d-flex align-items-center flex-column lms-text-muted py-10 px-4">
      {showImage && (
        <img src={src || "/media/svg/badges/empty-list.svg"} style={{ maxWidth: "100%" }} />
      )}
      {title && <b className="lms-font-bold h4 text-center my-2">{title}</b>}
      {message && <p className="text-center">{message}</p>}
    </div>
  );
}

export default EmptyList;
