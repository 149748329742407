import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const academicCalenderSlice = createSlice({
  name: "academicCalender",
  initialState,
  reducers: {
    updateAcademicCalender: (state, action) => {
      return { ...action.payload };
    }
  }
});
export default academicCalenderSlice.reducer;

export const { updateAcademicCalender } = academicCalenderSlice.actions;
