import React from "react";
import { useLottie } from "lottie-react";
import animationData from "constants/lottiesFiles/error-message.json";

const options = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function ErrorMessage({ handleOnClick }) {
  const { View } = useLottie(options, { height: "100%" });

  return (
    <div
      className="d-block mx-auto my-auto"
      style={{ maxWidth: "450px" }}
      onClick={handleOnClick && handleOnClick}
    >
      {View}
    </div>
  );
}

export default ErrorMessage;
