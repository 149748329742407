import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  earningList: []
};

const examModuleSlice = createSlice({
  name: "examModule",
  initialState,
  reducers: {
    updateEarningList: (state, action) => {
      state.earningList = [...action.payload.earningList];
    }
  }
});

export default examModuleSlice.reducer;

export const { updateEarningList } = examModuleSlice.actions;

export const getEarningList = createSelector(
  (state) => state.examModule,
  (examModule) => examModule.earningList
);
