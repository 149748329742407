import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  selectedStudent: {}
};

const userStudents = createSlice({
  name: "userStudents",
  initialState,
  reducers: {
    updateUserStudents: (state, action) => {
      state.students = action.payload;
    },
    updateSelectedStudent: (state, action) => {
      state.selectedStudent = action.payload;
    }
  }
});

export default userStudents.reducer;

export const { updateUserStudents, updateSelectedStudent } = userStudents.actions;

export const getUserStudents = createSelector(
  (state) => state.userStudents,
  (user) => user.students
);

export const getSelectedStudent = createSelector(
  (state) => state.userStudents,
  (user) => user.selectedStudent
);
