import IconLoader from "components/IconLoader/IconLoader";
import React from "react";

function BreadCrumbs({
  setSelectedLevel,
  setSelectedBranch,
  setSelectedModule,
  setSelectedTest,
  setSelectedQuestionIndex,
  setTestList,
  setQuestionList,
  selectedLevel,
  selectedModule,
  selectedBranch,
  selectedTest,
  selectedQuestionIndex,
  questionList
}) {
  return (
    <div className="rounded  container mx-auto" style={{ marginTop: 80 }}>
      <button
        className="btn btn-icon btn-xs"
        onClick={() => {
          setSelectedLevel(null);
          setSelectedBranch(null);
          setSelectedModule(null);
          setSelectedTest(null);
          setSelectedQuestionIndex(null);
          setTestList([]);
          setQuestionList([]);
        }}
      >
        <IconLoader icon="mdiHome" size={1} />
      </button>
      <button className="btn btn-icon btn-xs">
        <IconLoader icon="mdiChevronRight" size={1} />
      </button>

      {selectedLevel && (
        <button
          className="btn"
          onClick={() => {
            setSelectedModule(null);
            setSelectedBranch(null);
            setSelectedTest(null);
            setSelectedQuestionIndex(null);
            setTestList([]);
            setQuestionList([]);
          }}
        >
          {selectedLevel?.name}
        </button>
      )}
      {selectedModule && (
        <>
          <button className="btn btn-icon btn-xs">
            <IconLoader icon="mdiChevronRight" size={1} />
          </button>
          <button
            className="btn"
            onClick={() => {
              setSelectedBranch(null);
              setSelectedTest(null);
              setSelectedQuestionIndex(null);
              setTestList([]);
              setQuestionList([]);
            }}
          >
            {selectedModule?.name}
          </button>
        </>
      )}
      {selectedBranch && (
        <>
          <button className="btn btn-icon btn-xs btn-xs">
            <IconLoader icon="mdiChevronRight" size={1} />
          </button>
          <button
            className="btn"
            onClick={() => {
              setSelectedTest(null);
              setSelectedQuestionIndex(null);
            }}
          >
            {selectedBranch?.branchInfo?.name}
          </button>
        </>
      )}
      {selectedTest && (
        <>
          <button className="btn btn-icon btn-xs">
            <IconLoader icon="mdiChevronRight" size={1} />
          </button>
          <button className="btn" onClick={() => setSelectedQuestionIndex(null)}>
            {selectedTest?.name}
          </button>
        </>
      )}
      {selectedQuestionIndex !== null && (
        <>
          <button className="btn btn-icon btn-xs">
            <IconLoader icon="mdiChevronRight" size={1} />
          </button>
          <button className="btn">
            {questionList[selectedQuestionIndex]?.questionOrder}. Soru
          </button>
        </>
      )}
    </div>
  );
}

export default BreadCrumbs;
