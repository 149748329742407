import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  yksPreferenceList: []
};

const preferenceSlice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    setYksPreferenceList: (state, action) => {
      state.yksPreferenceList = [...action.payload.preferenceList];
    }
  }
});

export default preferenceSlice.reducer;

export const { setYksPreferenceList } = preferenceSlice.actions;

export const getYksPreferenceList = createSelector(
  (state) => state.preference,
  (preference) => preference.yksPreferenceList
);
