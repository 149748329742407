import apiInstance from "./apiInstance";

const checkUserAgreement = (params) => {
  return apiInstance.post("/enuseragreement/check", params);
};

const saveUserAgreement = (params) => {
  return apiInstance.post("/enuseragreement", params);
};

export { checkUserAgreement, saveUserAgreement };
