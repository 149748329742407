const tourSteps = [
  {
    selector: "#live-chat",
    content:
      "Yayın sohbeti ekranından sohbet akışını görüntülyebilir ve katılımcılar ile sohbet edebilirsiniz."
  },
  {
    selector: "#reload-button",
    content:
      "Yayın sohbeti akışını ve canlı yayın ekranını yenileyebilmek için sayfayı yenileme butonuna tıklayabilirsiniz"
  },
  {
    selector: "#go-live",
    content: "Canlı Yayın butonuna tıklayarak aktif olan canlı derse yönlendirilebilirsiniz."
  },
  {
    selector: "#content-detail",
    content:
      "İzlenilmek istenen ders seçildikten sonra açılan pencereden “►” ikonuna tıklayarak içeriği oynatabilirsiniz."
  },
  {
    selector: "#teacher-info",
    content: "İzlenilen dersi anlatan öğretmenin ad ve soyadını görüntüleyebilirsiniz."
  },
  {
    selector: "#content-date",
    content: "İzlenilen dersin ders tarihi ve saatini görüntüleyebilirsiniz."
  },
  {
    selector: "#content-duration",
    content: "İzlenilen dersin ders süresini görüntüleyebilirsiniz."
  },
  {
    selector: "#shared-item-101",
    content:
      "Ders 101 sekmesine tıklayarak dersler ile ilgili genel konu anlatım videolarını görüntüleyebilirsiniz."
  },
  {
    selector: "#shared-item-102",
    content:
      "Advanced sekmesine tıklayarak dersler ile ilgili detaylı konu anlatım videolarını görüntüleyebilirsiniz."
  },
  {
    selector: "#shared-item-PAST_VIDEOS",
    content:
      "İzleme Listesi’nden geçmiş derslerin ders videoları listesini görüntüleyebilir ve izlenmek istenilen derse tıklayarak ilgili ders videosunu izleyebilirsiniz."
  },
  {
    selector: "#shared-item-5DK",
    content:
      "5 DK Maraton sekmesine tıklayarak dersler ile ilgili 5 dakikalık hızlı konu anlatım videolarını görüntüleyebilirsiniz."
  },
  {
    selector: "#shared-item-LIVE_STREAM",
    content:
      "Livexamy sekmesine tıklayarak gün içindeki canlı ders bilgilerini görüntüleyebilir ve görüntülenen derse tıklayarak canlı yayına yönlendirilebilirsiniz."
  },
  {
    selector: "#shared-item-TEST",
    content:
      "Günlük Testler sekmesine tıklayarak günlük olarak tanımlanan testleri görüntüleyebilir ve testler üzerinde işlem yapabilirsiniz."
  }
];

export { tourSteps };
