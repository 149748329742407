import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  corporationList: null,
  campusList: null,
  schoolList: null
};

const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    updateCorporationList: (state, action) => {
      state.corporationList = action.payload;
    },
    updateCampusList: (state, action) => {
      state.campusList = action.payload;
    },
    updateSchoolList: (state, action) => {
      state.schoolList = action.payload;
    }
  }
});

export default domainSlice.reducer;

export const { updateCorporationList, updateCampusList, updateSchoolList } = domainSlice.actions;

export const getCorporationList = createSelector(
  (state) => state.domain,
  (domain) => domain.corporationList
);

export const getCampusList = createSelector(
  (state) => state.domain,
  (domain) => domain.campusList
);

export const getSchoolList = createSelector(
  (state) => state.domain,
  (domain) => domain.schoolList
);
