import { isFunction } from "lodash";

/**
 * @param {Object} params
 * @param {Function} params.service
 * @param {any} params.serviceParams
 * @param {Function} params.onSuccess
 * @param {Function} params.onError
 * @param {Boolean} params.showAnimation
 */
export default async function apiCall(params) {
  const { service, serviceParams, onSuccess, onError, showAnimation } = params;
  if (showAnimation) {
    // store.serviceCallStart()
  }
  return service(serviceParams)
    .then((response) => {
      //(response.status === "SUCCESS")
      // store.serviceCallSuccess()
      isFunction(onSuccess) && onSuccess(response);
      return response;
    })
    .catch((error) => {
      // store.serviceCallError()
      isFunction(onError) && onError(error);
      return error;
    });
}
