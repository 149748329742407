import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const questionPoolSlice = createSlice({
  name: "questionPool",
  initialState,
  reducers: {
    updateQuestionPool: (state, action) => {
      return [...action.payload];
    }
  }
});
export default questionPoolSlice.reducer;

export const { updateQuestionPool } = questionPoolSlice.actions;
