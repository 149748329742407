import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import App from "./App";
import store, { persistor } from "./store";
import LanguageProvider from "localization/LanguageProvider";
import I18nProvider from "localization/I18nProvider";
import { PersistGate } from "redux-persist/integration/react";
// STYLES
import "./assets/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from "react-router-dom";
import SplashScreen from "components/SplashScreen/SplashScreen";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  <Provider store={store}>
    <LanguageProvider>
      <I18nProvider>
        <PersistGate loading={<SplashScreen />} persistor={persistor}>
          <React.Suspense fallback={<SplashScreen />}>
            <BrowserRouter>
              <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                <App />
              </GoogleReCaptchaProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
        <ToastContainer />
      </I18nProvider>
    </LanguageProvider>
  </Provider>
);
