const IntegrationEnum = {
  MATIFIC: "MATIFIC",
  READING_MACHINE: "READING_MACHINE",
  KEY_CONNECT: "KEY_CONNECT",
  I_READ: "I_READ",
  LIVE_EXAMY: "LIVE_EXAMY",
  SOLVE_EXAMY: "SOLVE_EXAMY",
  OKUVARYUM: "OKUVARYUM",
  NEAR_POD: "NEAR_POD",
  TRACKLIGHT: "TRACKLIGHT",
  ALL_STAR: "ALL_STAR",
  MY_GAME: "MY_GAME"
};

const IntegrationNameEnum = {
  MATIFIC: "Matific",
  READING_MACHINE: "Reading Machine",
  KEY_CONNECT: "KeyConnect",
  I_READ: "iRead",
  LIVE_EXAMY: "Livexamy",
  SOLVE_EXAMY: "Solvexamy",
  OKUVARYUM: "Okuvaryum",
  NEAR_POD: "Near Pod",
  TRACKLIGHT: "Tracklight",
  ALL_STAR: "All Star",
  MY_GAME: "My Game"
};

const IntegrationImgSrcEnum = {
  MATIFIC: "/media/client-logos/matific.svg",
  READING_MACHINE: "/media/client-logos/reading_machine.svg",
  KEY_CONNECT: "/media/client-logos/key_connect.svg",
  I_READ: "/media/client-logos/i_read.svg",
  LIVE_EXAMY: "/media/client-logos/live_examy.svg",
  SOLVE_EXAMY: "/media/client-logos/solve_examy.svg",
  OKUVARYUM: "/media/client-logos/okuvaryum.svg",
  NEAR_POD: "/media/client-logos/near_pod.svg",
  TRACKLIGHT: "/media/menuItems/tracklight.svg",
  ALL_STAR: "/media/client-logos/all_star.svg",
  MY_GAME: "/media/client-logos/my-game.svg"
};

const IntegrationList = [
  {
    id: IntegrationEnum.MATIFIC,
    name: IntegrationNameEnum[IntegrationEnum.MATIFIC],
    bgColor: "#D0DBFF",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.MATIFIC],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.READING_MACHINE,
    name: IntegrationNameEnum[IntegrationEnum.READING_MACHINE],
    bgColor: "#E0FF9D",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.READING_MACHINE],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.KEY_CONNECT,
    name: IntegrationNameEnum[IntegrationEnum.KEY_CONNECT],
    bgColor: "#FBA91A30",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.KEY_CONNECT],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.I_READ,
    name: IntegrationNameEnum[IntegrationEnum.I_READ],
    bgColor: "#86CDF030",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.I_READ],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.LIVE_EXAMY,
    name: IntegrationNameEnum[IntegrationEnum.LIVE_EXAMY],
    bgColor: "#7738C7",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.LIVE_EXAMY],
    visibleOn: ["MENU"]
  },
  {
    id: IntegrationEnum.SOLVE_EXAMY,
    name: IntegrationNameEnum[IntegrationEnum.SOLVE_EXAMY],
    bgColor: "#FFDF8D",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.SOLVE_EXAMY],
    visibleOn: []
  },
  {
    id: IntegrationEnum.OKUVARYUM,
    name: IntegrationNameEnum[IntegrationEnum.OKUVARYUM],
    bgColor: "#FEBBFF",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.OKUVARYUM],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.NEAR_POD,
    name: IntegrationNameEnum[IntegrationEnum.NEAR_POD],
    bgColor: "#B1DAFF",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.NEAR_POD],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.TRACKLIGHT,
    name: IntegrationNameEnum[IntegrationEnum.TRACKLIGHT],
    bgColor: "#7738C730",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.TRACKLIGHT],
    visibleOn: ["MENU"]
  },
  {
    id: IntegrationEnum.ALL_STAR,
    name: IntegrationNameEnum[IntegrationEnum.ALL_STAR],
    bgColor: "#B1DAFF",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.ALL_STAR],
    visibleOn: ["DASHBOARD"]
  },
  {
    id: IntegrationEnum.MY_GAME,
    name: IntegrationNameEnum[IntegrationEnum.MY_GAME],
    bgColor: "#fff",
    imgSrc: IntegrationImgSrcEnum[IntegrationEnum.MY_GAME],
    visibleOn: ["DASHBOARD"]
  }
];

export { IntegrationEnum, IntegrationList };
