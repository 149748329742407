import { createSelector, createSlice } from "@reduxjs/toolkit";

//! TODO: Burası komple silinecek

const initialState = {
  reportValues: {},
  quotaReportValues: {},
  lastUpdateDate: null
};

const accountingModuleSlice = createSlice({
  name: "accountingModule",
  initialState,
  reducers: {
    updateContractReportValues: (state, action) => {
      state.reportValues[action.payload.termId] = [...action.payload.contractList];
    },
    updateQuotaReportValues: (state, action) => {
      state.quotaReportValues[action.payload.termId] = [...action.payload.quotaReportList];
    },
    updateLastUpdateDate: (state, action) => {
      state.lastUpdateDate = action.payload;
    },
    resetAccountingModule: (state, action) => {
      state.reportValues = {};
      state.quotaReportValues = {};
      state.lastUpdateDate = null;
    }
  }
});

export default accountingModuleSlice.reducer;

export const {
  updateContractReportValues,
  updateQuotaReportValues,
  updateLastUpdateDate,
  resetAccountingModule
} = accountingModuleSlice.actions;

export const getReportValues = createSelector(
  (state) => state.accountingModule,
  (accountingModule) => accountingModule.reportValues
);

export const getQuotaReports = createSelector(
  (state) => state.accountingModule,
  (accountingModule) => accountingModule.quotaReportValues
);

export const getLastUpdateDate = createSelector(
  (state) => state.accountingModule,
  (accountingModule) => accountingModule.lastUpdateDate
);
