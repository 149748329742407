import apiInstance from "./apiInstance";

const getImParkBookList = () => {
  return apiInstance.get(`/impark/getbooks`);
};

const addImParkModule = (params) => {
  return apiInstance.post(`/impark/module`, params);
};

const deleteImParkModule = (moduleId) => {
  return apiInstance.delete(`/impark/module/${moduleId}`);
};

const getImParkModule = () => {
  return apiInstance.get(`/impark/getModules`);
};

const getImParkBookTestById = (bookId) => {
  return apiInstance.get(`/impark/books/${bookId}`);
};

const getImParkBookTestVideosByBookIdAndTestId = ({ bookId, testId }) => {
  return apiInstance.get(`/impark/books/${bookId}/${testId}`);
};

export {
  getImParkBookList,
  addImParkModule,
  getImParkModule,
  getImParkBookTestById,
  getImParkBookTestVideosByBookIdAndTestId,
  deleteImParkModule
};
