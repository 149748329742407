import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import userSlice from "./slices/userSlice";
import awsSlice from "./slices/awsSlice";
import termSlice from "./slices/termSlice";
import accountingModuleSlice from "./slices/accountingModuleSlice";
import questionPoolSlice from "./slices/questionPoolSlice";
import livexamyQuestionPool from "./slices/livexamyQuestionPool";
import studentStudySlice from "./slices/studentSlice";
import academiCalenderSlice from "./slices/academiCalenderSlice";
import domainSlice from "./slices/domainSlice";
import preferenceSlice from "./slices/preferenceSlice";
import examModuleSlice from "./slices/examModuleSlice";
import userStudentsSlice from "./slices/userStudentsSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["domain"] // navigation will not be persisted!
  // whitelist: ["aws"] // only navigation will be persisted
};

const reducers = combineReducers({
  user: userSlice,
  aws: awsSlice,
  term: termSlice,
  accountingModule: accountingModuleSlice,
  questionPool: questionPoolSlice,
  livexamyQuestionPool: livexamyQuestionPool,
  studentStudy: studentStudySlice,
  academicCalender: academiCalenderSlice,
  domain: domainSlice,
  preference: preferenceSlice,
  examModule: examModuleSlice,
  userStudents: userStudentsSlice
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    localStorage.removeItem("persist:root");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

export const persistor = persistStore(store);

export default store;

// const resettableRootReducer = (state, action) => {
//   if (action.type === "store/reset") {
//     return rootReducer(undefined, action);
//   }
//   return rootReducer(state, action);
// };
