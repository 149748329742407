import { UserAgreementVersion } from "constants/enums/UserAgreementEnum";
import showToast from "helpers/showToast";
import { useSelector } from "react-redux";
import apiCall from "services/apiCall";
import { checkUserAgreement, saveUserAgreement } from "services/user-agreement-service";
import { getIsUserAgreementShown } from "store/slices/userSlice";

export default function useUserAgreement({ onConfirmSuccess, onCheckSuccess }) {
  const isAgreementShown = useSelector(getIsUserAgreementShown);
  const confirmUserAgreement = (identityNumber) => {
    apiCall({
      service: saveUserAgreement,
      serviceParams: {
        identityNumber: identityNumber,
        agreementVersion: UserAgreementVersion
      },
      onSuccess: (res) => {
        if (res.status === "SUCCESS") {
          onConfirmSuccess(res);
        } else if (res.status === "ERROR") {
          showToast(res?.message || "KVKK onaylama işlemi sırasında hata oluştu.", {
            type: "error"
          });
        }
      },
      onError: () => {
        showToast("KVKK onaylama işlemi sırasında hata oluştu.", { type: "error" });
      }
    });
  };

  const checkUserAgreementFromApi = (identityNumber) => {
    if (process.env.NODE_ENV === "production" && !isAgreementShown) {
      apiCall({
        service: checkUserAgreement,
        serviceParams: {
          identityNumber: identityNumber,
          agreementVersion: UserAgreementVersion
        },
        onSuccess: (res) => {
          if (res.status === "SUCCESS") {
            onCheckSuccess(res);
          } else if (res.status === "ERROR") {
            showToast(res?.message || "KVKK onay arama işlemi sırasında hata oluştu.", {
              type: "error"
            });
          }
        },
        onError: () => {
          showToast("KVKK onay arama işlemi sırasında hata oluştu.", { type: "error" });
        }
      });
    } else {
      onCheckSuccess({ data: true });
    }
  };

  return { confirmUserAgreement, checkUserAgreementFromApi };
}
