const ParentMenuEnum = {
  "corporation-operations": {
    id: "corporation-operations",
    name: "Marka İşlemleri",
    icon: "/media/menuItems/marka-islemleri.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-corporation-campus-school-operations"
  },
  "accounting-operations": {
    id: "accounting-operations",
    name: "Sözleşme İşlemleri",
    icon: "/media/svg/topMenu/contract.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "exam-operations": {
    id: "exam-operations",
    name: "Sınav İşlemleri",
    icon: "/media/svg/topMenu/exam.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "announcement-operations": {
    id: "announcement-operations",
    name: "Mesaj İşlemleri",
    icon: "/media/menuItems/announcement.png",
    color: "#90CAF9",
    subMenuList: []
  },
  "campus-operations": {
    id: "campus-operations",
    name: "Kampüs İşlemleri",
    icon: "/media/menuItems/42.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-corporation-campus-school-operations"
  },
  "school-operations": {
    id: "school-operations",
    name: "Kurum İşlemleri",
    icon: "/media/menuItems/46.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-corporation-campus-school-operations"
  },
  "role-operations": {
    id: "role-operations",
    name: "Rol İşlemleri",
    icon: "/media/svg/icons/role.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "registration-operations": {
    id: "registration-operations",
    name: "Öğrenci İşlemleri",
    // icon: "/media/menuItems/kullanici-islemleri.png",
    icon: "/media/menuItems/22.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-user-operations"
  },
  "personnel-operations": {
    id: "personnel-operations",
    name: "Personel İşlemleri",
    icon: "/media/menuItems/33.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-user-operations"
  },
  "course-lesson-operations": {
    id: "course-lesson-operations",
    name: "Ders İşlemleri",
    icon: "/media/menuItems/dersler.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-course-operations"
  },
  "content-producer-operations": {
    id: "content-producer-operations",
    name: "İçerik Üretici",
    icon: "/media/menuItems/icerik-uretici.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-course-operations"
  },
  "self-id": {
    id: "self-id",
    name: "The Self Id",
    icon: "/media/svg/topMenu/selfid-icon.svg",
    color: "",
    subMenuList: []
    // topMenuParentId: "top-menu-course-operations"
  },
  "definitions-operations": {
    id: "definitions-operations",
    name: "Tanımlamalar",
    icon: "/media/menuItems/tanimlar.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-course-operations"
  },
  "tracklight-operations": {
    id: "tracklight-operations",
    name: "Tracklight",
    icon: "/media/menuItems/tracklight.png",
    color: "#90CAF9",
    subMenuList: [],
    topMenuParentId: "top-menu-course-operations"
  }
};

export { ParentMenuEnum };
