import { ClubStudentRouteList, StudentRouteList } from "constants/StudentRouteList";
import { RoleTypeEnum } from "constants/enums/RoleEnum";

const getStudentRoutes = (auth, clients, selectedAuth) => {
  const limitRoutes =
    selectedAuth?.roleType === RoleTypeEnum.STUDENT
      ? auth?.details?.clubStudent
      : auth?.clubStudent;
  return (limitRoutes ? ClubStudentRouteList : StudentRouteList)?.filter((route) =>
    route.checkPermission
      ? route.checkPermission(auth, clients, route.permission, selectedAuth)
      : true
  );
};

const getStudentMenu = (auth, clients, selectedAuth) => {
  const menuList = getStudentRoutes(auth, clients, selectedAuth)?.filter((route) => route?.menu);
  return menuList?.length > 1 ? menuList : [];
};

export { getStudentRoutes, getStudentMenu };
