import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

function ProtectedRoute() {
  let location = useLocation();
  const user = useSelector((state) => state.user);

  if (!user.access_token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
export default ProtectedRoute;
