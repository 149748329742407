import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

export default function showToast(message, toastProps) {
  const ToastRenderer = () => {
    return <FormattedMessage id={message} />;
  };
  toast(<ToastRenderer />, { type: "info", theme: "colored", autoClose: 3500, ...toastProps });
}
