const { createSlice, createSelector } = require("@reduxjs/toolkit");

const initialState = {
  hashes: {}
};

const awsSlice = createSlice({
  name: "aws",
  initialState,
  reducers: {
    addAws: (state, action) => {
      const { sha1, url, timestamp } = action.payload;
      state.hashes = {
        ...(state.hashes || {}),
        [sha1]: {
          url: url,
          timestamp: timestamp
        }
      };
    },
    removeHash: (state, action) => {
      // TODO: burası silinebilir
      delete state.hashes[action.payload];
    }
  }
});

export default awsSlice.reducer;

export const { addAws, removeHash } = awsSlice.actions;

export const getAwsImage = createSelector(
  (state) => state.aws,
  (aws) => aws.hashes
);
