import React from "react";
import "./splash-screen.scss";
function SplashScreen() {
  return (
    <div className="splash-screen">
      <img src="/media/logos/logo-examy.png" alt="Sınav logo" />
      <svg className="splash-spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
    </div>
  );
}

export default SplashScreen;
