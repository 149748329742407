import React from "react";
import { IntlProvider } from "react-intl";
import { useLang } from "./LanguageFunctions";
import trMessages from "./messages/tr.json";
import enMessages from "./messages/en.json";

const allMessages = {
  tr: trMessages,
  en: enMessages
};

function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      onError={(err) => {
        if (typeof err === "string" && err.startsWith("[React Intl] Missing message:")) {
          //console.warn("Missing translation", err);
          return;
        }
      }}
    >
      {children}
    </IntlProvider>
  );
}

export default I18nProvider;
