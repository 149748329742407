import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const studentStudySlice = createSlice({
  name: "studyItem",
  initialState,
  reducers: {
    updateSelectedStudyItem: (state, action) => {
      return { ...action.payload };
    }
  }
});
export default studentStudySlice.reducer;

export const { updateSelectedStudyItem } = studentStudySlice.actions;
