import { createSelector, createSlice } from "@reduxjs/toolkit";
import { sort } from "fast-sort";

const initialState = {
  id: "",
  sub: "",
  identity: "",
  groups: "",
  contacts: "",
  notes: [],
  todoList: [],
  inbox: {
    messagess: [],
    unReadCount: 0,
    unReadMessage: true
  },
  roles: [],
  selectedAuthorization: null,
  access_token: null,
  schoolTree: {
    roleSchoolList: {},
    selectedSchoolList: []
  },
  externalResource: [],
  clients: [],
  studentGroups: [],
  availableSurvey: false,
  isUserAgreementShown: false,
  authSelectModal: false
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state = Object.assign(state, action.payload);
    },
    logout: () => initialState,
    changeAuthorization: (state, action) => {
      state.selectedAuthorization = action.payload;
    },
    updateRoleSchoolList: (state, action) => {
      state.schoolTree.roleSchoolList[action.payload.roleId] = action.payload.schoolList;
    },
    updateSelectedSchoolList: (state, action) => {
      state.schoolTree.selectedSchoolList = action.payload;
    },
    updateCurrentRoleOperationList: (state, action) => {
      state.selectedAuthorization.operations = action.payload;
      state.roles = state.roles.map((role) => {
        if (role.id === state.selectedAuthorization.id) {
          role.operations = action.payload;
        }
        return role;
      });
    },
    updateRoles: (state, action) => {
      state.roles = action.payload;
    },
    changeContacts: (state, action) => {
      state.contacts = action.payload;
    },
    changeIdentity: (state, action) => {
      state.identity = action.payload;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    deleteNoteFromRedux: (state, action) => {
      state.notes = state.notes.filter((note) => note.id !== action.payload);
    },
    deleteToDoFromRedux: (state, action) => {
      state.todoList = state.todoList.filter((todo) => todo.id !== action.payload);
    },
    changeTodoList: (state, action) => {
      state.todoList = action.payload;
    },
    setInboxUnReadCount: (state, action) => {
      state.inbox.unReadCount = action.payload;
    },
    setUnReadMessage: (state, action) => {
      state.inbox.unReadMessage = action.payload;
    },
    setExternalResource: (state, action) => {
      state.externalResource = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setStudentGroups: (state, action) => {
      state.studentGroups = action.payload;
    },
    updateUserDashboard: (state, action) => {
      state.selectedAuthorization.dashBoard = action.payload;
    },
    updateAvailableSurvey: (state, action) => {
      state.availableSurvey = action.payload;
    },
    updateIsUserAgreementShown: (state, action) => {
      state.isUserAgreementShown = action.payload;
    },
    setAuthSelectModal: (state, action) => {
      state.authSelectModal = action.payload;
    },
    setContractShareWithParent: (state, action) => {
      const tempSelectedAuthorization = {
        ...state.selectedAuthorization,
        contractShareWithParent: action.payload
      };
      state.selectedAuthorization = tempSelectedAuthorization;
    }
  }
});

export default userSlice.reducer;

export const {
  login,
  logout,
  changeAuthorization,
  updateRoleSchoolList,
  updateSelectedSchoolList,
  updateCurrentRoleOperationList,
  changeContacts,
  changeIdentity,
  setNotes,
  deleteNoteFromRedux,
  deleteToDoFromRedux,
  changeTodoList,
  setInboxUnReadCount,
  setUnReadMessage,
  setExternalResource,
  setClients,
  updateRoles,
  setStudentGroups,
  updateUserDashboard,
  updateAvailableSurvey,
  updateIsUserAgreementShown,
  setAuthSelectModal,
  setContractShareWithParent
} = userSlice.actions;

export const getSelectedAuthorizationCorporationId = createSelector(
  (state) => state.user,
  (user) => user.selectedAuthorization.permCode?.split("-")[0]
);

export const getSelectedAuthorization = createSelector(
  (state) => state.user,
  (user) => user.selectedAuthorization
);

export const getSelectedAuthorizationDashboard = createSelector(
  (state) => state.user,
  (user) => user.selectedAuthorization?.dashBoard
);

export const getRoleType = createSelector(
  (state) => state.user.selectedAuthorization,
  (user) => user.selectedAuthorization.roleType
);

export const getUserIdentity = createSelector(
  (state) => state.user,
  (user) => user.identity
);

export const getUserContacts = createSelector(
  (state) => state.user,
  (user) => user.contacts
);

export const getUserGroups = createSelector(
  (state) => state.user,
  (user) => user.groups
);

export const getOperations = createSelector(
  (state) => state.user,
  (user) => user.selectedAuthorization.operations
);

export const getCurrentUserId = createSelector(
  (state) => state.user,
  (user) => user.id
);

export const getUserRoles = createSelector(
  (state) => state.user,
  (user) => user.roles
);

export const getUserNotes = createSelector(
  (state) => state.user,
  (user) => user.notes
);

export const getSelectedRoleCampusList = createSelector(
  (state) => state,
  (state) => {
    const selectedAuth = state?.user?.selectedAuthorization;
    const schoolList = state?.user?.schoolTree?.roleSchoolList || {};
    const campusList = [];
    (schoolList[selectedAuth?.id] || []).forEach((school) => {
      if (!campusList.find((_campus) => _campus.id === school.campusId)) {
        campusList.push({
          id: school.campusId,
          name: school.campusName,
          permCode: `${school.corporationId}-${school.campusId}`,
          corporationId: school.corporationId,
          corporationName: school.corporationName
        });
      }
    });
    return sort(campusList || []).asc((campus) => campus?.name) || [];
  }
);

export const getSelectedRoleCorporationList = createSelector(
  (state) => state,
  (state) => {
    const selectedAuth = state?.user?.selectedAuthorization;
    const schoolList = state?.user?.schoolTree?.roleSchoolList || {};
    const corporationList = [];
    (schoolList[selectedAuth?.id] || []).forEach((school) => {
      if (!corporationList.find((corporation) => corporation.id === school.corporationId)) {
        corporationList.push({
          id: school.corporationId,
          name: school.corporationName
        });
      }
    });
    return sort(corporationList || []).asc((corporation) => corporation?.name) || [];
  }
);

export const getSelectedRoleCampusSchools = createSelector(
  (state) => state,
  (state) => {
    const selectedAuth = state?.user?.selectedAuthorization;
    const schoolList = state?.user?.schoolTree?.roleSchoolList || {};
    const campusSchool = {};
    (schoolList[selectedAuth?.id] || []).forEach((school) => {
      if (campusSchool[school.campusId]) {
        campusSchool[school.campusId] = [...campusSchool[school.campusId], school];
      } else {
        campusSchool[school.campusId] = [school];
      }
    });
    return campusSchool;
  }
);

export const getRoleSchoolList = createSelector(
  (state) => state,
  (state) => {
    const selectedAuth = state?.user?.selectedAuthorization;
    const schoolList = state?.user?.schoolTree?.roleSchoolList || {};

    return schoolList[selectedAuth?.id] || [];
  }
);

export const getSelectedRoleSchoolIdList = createSelector(
  (state) => state,
  (state) => {
    const selectedAuth = state?.user?.selectedAuthorization;
    const schoolList = state?.user?.schoolTree?.roleSchoolList || {};

    return schoolList[selectedAuth?.id]?.map((school) => school.id) || [];
  }
);

export const getSelectedRoleSchoolList = createSelector(
  (state) => state,
  (state) => {
    const selectedAuth = state?.user?.selectedAuthorization;
    const schoolList = state?.user?.schoolTree?.roleSchoolList[selectedAuth?.id];
    return sort(schoolList || []).asc((school) => school?.name) || [];
  }
);

export const getTodoList = createSelector(
  (state) => state.user,
  (state) => state.todoList
);

export const getInboxMessageCount = createSelector(
  (state) => state.user,
  (state) => state?.inbox?.unReadCount
);

export const showUnReadMessage = createSelector(
  (state) => state.user,
  (state) => state?.inbox?.unReadMessage
);

export const getSelectedStudentGroups = createSelector(
  (state) => state.user,
  (state) => state?.studentGroups
);

export const getIsUserAgreementShown = createSelector(
  (state) => state.user,
  (state) => state?.isUserAgreementShown
);

export const getAuthSelectModal = createSelector(
  (state) => state.user,
  (state) => state?.authSelectModal
);
