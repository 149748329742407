const CodeExamyPermissons = [
  {
    id: "62a2300b050a332fdf9d7986",
    name: "Kodlama ve Robotik",
    code: "KOD"
  },
  {
    id: "6315b6ac88e78a1a209abe66",
    name: "BİLGİSAYAR BİLİMİ",
    code: "BİLG. BİL."
  },
  {
    id: "6315b1ad88e78a1a2094eb1f",
    name: "BİLİŞİM TEKNOLOJİLERİ VE YAZILIM/KODLAMA",
    code: "KOD."
  },
  {
    id: "62a2300c050a332fdf9d79fd",
    name: "Bilişim Teknolojileri",
    code: "BIL.TEKN."
  }
];

const ReadingMachinePermissions = [
  {
    id: "62a2300b050a332fdf9d79a8",
    name: "İngilizce",
    code: "ING"
  }
];

const MatificPermissions = [
  {
    id: "62a2300b050a332fdf9d79a8",
    name: "İngilizce",
    code: "ING"
  },
  {
    id: "62a2300a050a332fdf9d7920",
    name: "Matematik",
    code: "MAT"
  },
  {
    id: "62becc0b95d0bf779f2fca35",
    name: "Sınıf Öğretmeni",
    code: "S.ÖĞR"
  }
];

const CheckPermisson = (selectedAuthorization, permissions) => {
  if (Array.isArray(permissions) && selectedAuthorization) {
    const teacherBranch = selectedAuthorization?.details?.teacherDetail?.graduationBranchInfo;
    return permissions.find((branch) => branch.id === teacherBranch?.id);
  } else {
    return false;
  }
};

const CheckExternalResourse = (user, externalResourses) => {
  // const ExternalResources = ["READING_MACHINE", "SINAV_GLOBAL"];
  if (Array.isArray(user?.externalResource) && externalResourses?.length > 0) {
    return externalResourses?.find((resource) =>
      user?.externalResource?.find((_external) => _external === resource)
    );
  } else {
    return false;
  }
};

const SupereadPermission = (selectedAuthorization) => {
  const blockCampusIdList = [
    "62e90d5d716c517e37243fe0" // akçaabat sınav kampüs
  ];
  return !blockCampusIdList.includes(selectedAuthorization?.permCode?.split("-")[1]);
};

export {
  CodeExamyPermissons,
  ReadingMachinePermissions,
  MatificPermissions,
  CheckPermisson,
  CheckExternalResourse,
  SupereadPermission
};
