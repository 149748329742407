import { uniqBy } from "lodash";
import apiCall from "services/apiCall";
import BreadCrumbs from "./components/BreadCrumbs";
import React, { useEffect, useState } from "react";
import EmptyList from "components/Animations/EmptyList";
import IconLoader from "components/IconLoader/IconLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getImParkModule,
  getImParkBookTestById,
  getImParkBookTestVideosByBookIdAndTestId
} from "services/impark-service";

function ModuleSolution() {
  const navigate = useNavigate();
  const goLogin = () => navigate("/login");

  const [testLoading, setTestLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [levelList, setLevelList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [questionList, setQuestionList] = useState([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getModuleListFromApi();
  }, []);

  useEffect(() => {
    if (moduleList) {
      const tempLevelList = moduleList.map((item) => item?.levelInfo);
      setLevelList(uniqBy(tempLevelList?.flat(), "id"));
    }
  }, [moduleList]);

  useEffect(() => {
    if (selectedBranch) {
      getBookTestFromApi();
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedTest && selectedBranch) getVideosFromApi();
  }, [selectedTest, selectedBranch]);

  const getModuleListFromApi = async () => {
    apiCall({
      service: getImParkModule,
      onSuccess: (res) => {
        setModuleList(res.data);
      }
    });
  };

  const getBookTestFromApi = async () => {
    setTestLoading(true);
    await apiCall({
      service: getImParkBookTestById,
      serviceParams: selectedBranch?.imParkBookId,
      onSuccess: (res) => {
        setTestList(res.data);
      }
    });
    setTestLoading(false);
  };

  const getVideosFromApi = async () => {
    setQuestionLoading(true);
    await apiCall({
      service: getImParkBookTestVideosByBookIdAndTestId,
      serviceParams: { bookId: selectedBranch?.imParkBookId, testId: selectedTest?.id },
      onSuccess: (res) => {
        setQuestionList(res?.data);
      }
    });
    setQuestionLoading(false);
  };

  useEffect(() => {
    const level = searchParams.get("kademe");
    if (levelList) {
      setSelectedLevel(levelList.find((item) => item.code === level));
    }
  }, [searchParams, levelList]);

  useEffect(() => {
    if (moduleList?.length > 0 && selectedLevel) {
      const module = searchParams.get("modul");
      const filteredModul = moduleList.filter((item) =>
        item?.levelInfo?.map((l) => l?.id)?.includes(selectedLevel?.id)
      );
      const tempSelectedModule = filteredModul.find(
        (_module) => _module?.name?.split("-")?.[1] === module
      );
      setSelectedModule(tempSelectedModule);
    }
  }, [selectedLevel, searchParams, moduleList]);

  useEffect(() => {
    if (searchParams && selectedModule) {
      const branch = searchParams.get("brans");
      const tempSelectedBranch = selectedModule?.bookList?.find(
        (book) => book?.branchInfo?.code === branch
      );
      setSelectedBranch(tempSelectedBranch);
    }
  }, [selectedModule, searchParams]);

  return (
    <div>
      <nav
        style={{
          top: -5,
          margin: 0,
          width: "100%",
          position: "fixed",
          background: "white",
          zIndex: 2
        }}
      >
        <div className="container w-100 justify-content-between d-flex py-3">
          <img
            src="/media/logos/logo-new.svg"
            className="my-auto cursor-pointer"
            width={"145"}
            height={"49"}
            onClick={() => navigate("/dashboard")}
          />

          <button
            className="btn rounded-xl my-auto"
            style={{ color: "#06AE56", border: "solid 1px #06AE56" }}
            onClick={goLogin}
          >
            <IconLoader icon="mdiAccountOutline" size={1} />
            Oturum Aç
          </button>
        </div>
      </nav>

      <BreadCrumbs
        setSelectedLevel={setSelectedLevel}
        setSelectedBranch={setSelectedBranch}
        setSelectedModule={setSelectedModule}
        setSelectedTest={setSelectedTest}
        setSelectedQuestionIndex={setSelectedQuestionIndex}
        setTestList={setTestList}
        setQuestionList={setQuestionList}
        selectedLevel={selectedLevel}
        selectedModule={selectedModule}
        selectedBranch={selectedBranch}
        selectedTest={selectedTest}
        selectedQuestionIndex={selectedQuestionIndex}
        questionList={questionList}
      />
      <div
        className="container mx-auto rounded-lg my-8"
        style={{ background: "rgba(214, 233, 253, 1)" }}
      >
        {!moduleList?.length && (
          <EmptyList message="Çözüm videoları henüz eklenmedi." title="Çözüm Videoları" />
        )}
        {!selectedLevel &&
          levelList.map((level) => (
            <ItemButton
              key={level?.id}
              label={level?.name}
              onClick={() => setSelectedLevel(level)}
            />
          ))}

        {!selectedModule &&
          selectedLevel &&
          moduleList
            .filter((item) => item?.levelInfo?.map((l) => l?.id)?.includes(selectedLevel?.id))
            .map((item) => (
              <ItemButton
                key={item?.id}
                label={item?.name}
                onClick={() => setSelectedModule(item)}
              />
            ))}

        {!selectedBranch &&
          selectedModule &&
          selectedModule.bookList.map((book) => (
            <ItemButton
              key={book.imParkBookId}
              label={book?.branchInfo?.name}
              onClick={() => setSelectedBranch(book)}
            />
          ))}

        {selectedBranch && testLoading && <div>Yükleniyor...</div>}

        {selectedBranch && !testLoading && testList.length === 0 && (
          <EmptyList message="Bu kitap için test bulunmamaktadır." title="Test Bulunamadı" />
        )}

        {!selectedTest &&
          selectedBranch &&
          !testLoading &&
          testList.map((test) => (
            <ItemButton key={test.id} label={test?.name} onClick={() => setSelectedTest(test)} />
          ))}

        {selectedQuestionIndex === null &&
          selectedTest &&
          questionList.map((question, index) => (
            <ItemButton
              key={question?.questionOrder}
              label={`${question?.questionOrder}. Soru`}
              onClick={() => setSelectedQuestionIndex(index)}
            />
          ))}

        {!questionLoading && selectedTest && questionList.length === 0 && (
          <EmptyList message="Bu test için soru bulunmamaktadır." title="Soru Bulunamadı" />
        )}
        {questionLoading && <div>Yükleniyor...</div>}

        {selectedTest && selectedQuestionIndex !== null && (
          <>
            <div
              key={questionList[selectedQuestionIndex]?.mp4Path}
              className="mx-auto col-lg-6 col-12"
            >
              <video width="100%" controls className="mx-auto">
                <source src={questionList[selectedQuestionIndex]?.mp4Path} type="video/mp4" />
              </video>

              <div className="d-flex justify-content-between mb-10">
                <button
                  className="btn btn-primary"
                  disabled={selectedQuestionIndex === 0}
                  onClick={() => setSelectedQuestionIndex(selectedQuestionIndex - 1)}
                >
                  <IconLoader icon="mdiChevronLeft" size={1} />
                </button>
                <button
                  className="btn btn-primary"
                  disabled={selectedQuestionIndex === questionList.length - 1}
                  onClick={() => setSelectedQuestionIndex(selectedQuestionIndex + 1)}
                >
                  <IconLoader icon="mdiChevronRight" size={1} />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const ItemButton = ({ label, onClick }) => {
  return (
    <div className="col-lg-6 col-12 mx-auto">
      <div
        className="rounded-lg my-5 text-center py-2 bg-hover-white"
        style={{
          border: "solid rgba(116, 158, 238, 1) 1px",
          background: "rgba(248, 248, 248, 1)"
        }}
        onClick={onClick}
      >
        <h1 className="p-0 m-0">{label}</h1>
      </div>
    </div>
  );
};
export default ModuleSolution;
