import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  termList: [],
  currentTermId: null,
  selectedTerm: null
};

const termSlice = createSlice({
  name: "term",
  initialState,
  reducers: {
    updateTermState: (state, action) => {
      state.termList = [...action.payload.termList];
      state.currentTermId = action.payload.currentTermId;
      state.selectedTerm = action.payload.selectedTerm;
    },
    setSelectedTerm: (state, action) => {
      state.selectedTerm = action.payload;
    }
  }
});

export default termSlice.reducer;

export const { updateTermState, setSelectedTerm } = termSlice.actions;

export const getTermList = createSelector(
  (state) => state.term,
  (term) => term.termList
);

export const getCurrentTermId = createSelector(
  (state) => state.term,
  (term) => term.currentTermId
);

export const getSelectedTerm = createSelector(
  (state) => state.term,
  (term) => term.selectedTerm
);

export const getActiveTerm = createSelector(
  (state) => state.term,
  (term) => term.termList.find((t) => t.id === term.currentTermId)
);
