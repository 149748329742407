import { AdminSideTopMenuEnum } from "constants/enums/AdminSideTopMenuEnum";
import { OperationEnum } from "constants/enums/OperationEnum";
import { ParentMenuEnum } from "constants/enums/ParentMenuEnum";
import { RoleEnum } from "constants/enums/RoleEnum";
import { CheckExternalResourse, CheckPermisson } from "constants/Permissions";
import { NearPodRouteList, RouteList } from "constants/RouteList";
import { isFunction } from "lodash";

const getAuthorizedRoutes = (userOperationList, roleType, selectedAuthorization, user) => {
  if (roleType === RoleEnum.ADMIN) {
    return RouteList;
  } else if (userOperationList) {
    if (userOperationList?.includes(OperationEnum.NEAR_POD_ADMIN)) {
      return (
        NearPodRouteList?.filter((route) =>
          route?.code?.some((routeCode) => userOperationList.includes(routeCode))
        ) || []
      );
    } else {
      return RouteList.filter((route) => {
        if (isFunction(route?.permissionFun)) {
          return route.permissionFun(selectedAuthorization);
        } else if (route.common) {
          return true;
        } else if (route.external) {
          return CheckExternalResourse(user, route.external);
        } else if (!route.external && route.permission) {
          return CheckPermisson(selectedAuthorization, route.permission);
        } else {
          return route.code?.some((routeCode) => userOperationList.includes(routeCode));
        }
      });
    }
  } else {
    return [];
  }
};

const getAuthorizedMenus = (userOperationList, roleType, selectedAuthorization, user) => {
  if (roleType === RoleEnum.ADMIN) {
    return RouteList.filter((route) => route.menu);
  } else if (userOperationList) {
    if (userOperationList?.includes(OperationEnum.NEAR_POD_ADMIN)) {
      return (
        NearPodRouteList?.filter((route) =>
          route?.code?.some((routeCode) => userOperationList.includes(routeCode) && route?.menu)
        ) || []
      );
    } else {
      return RouteList.filter((route) => {
        if (route.menu) {
          if (isFunction(route?.permissionFun)) {
            return route.permissionFun(selectedAuthorization);
          } else if (route.common && (roleType !== RoleEnum.CONSERVATOR || route?.showParent)) {
            return true;
          } else if (route.external) {
            return CheckExternalResourse(user, route.external);
          } else if (!route.external && route.permission) {
            return CheckPermisson(selectedAuthorization, route.permission);
          } else {
            return route.code?.some((routeCode) => userOperationList.includes(routeCode));
          }
        }
      });
    }
  } else {
    return [];
  }
};

const generateMenu = (operationList, roleType, selectedAuthorization, user) => {
  const menuList = [];
  const authorizedMenus = getAuthorizedMenus(operationList, roleType, selectedAuthorization, user);
  authorizedMenus.forEach((route) => {
    if (route.hasOwnProperty("parentId")) {
      const existingParent = menuList.find((menu) => menu.id === route.parentId);
      if (existingParent) {
        existingParent.subMenuList.push(route);
      } else {
        const parentMenu = { ...ParentMenuEnum[route.parentId], subMenuList: [] };
        parentMenu.subMenuList.push(route);
        menuList.push(parentMenu);
      }
    } else {
      menuList.push(route);
    }
  });
  const topMenuItems = [];

  menuList?.forEach((menuItem) => {
    if (menuItem.hasOwnProperty("topMenuParentId")) {
      const existingParent = topMenuItems.find((menu) => menu.id === menuItem.topMenuParentId);
      if (existingParent) {
        existingParent.subMenuList.push(menuItem);
      } else {
        const parentTopMenu = {
          ...AdminSideTopMenuEnum[menuItem.topMenuParentId],
          subMenuList: []
        };
        parentTopMenu.subMenuList.push(menuItem);
        topMenuItems.push(parentTopMenu);
      }
    } else {
      topMenuItems.push(menuItem);
    }
  });
  return topMenuItems;
};

export { getAuthorizedRoutes, generateMenu };
