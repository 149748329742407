const AdminSideTopMenuEnum = {
  "top-menu-applications": {
    id: "top-menu-applications",
    name: "Uygulamalar",
    icon: "/media/svg/topMenu/star.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "top-menu-corporation-campus-school-operations": {
    id: "top-menu-corporation-campus-school-operations",
    name: "Kurum İşlemleri",
    icon: "/media/svg/topMenu/corporationCampusSchool.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "top-menu-user-operations": {
    id: "top-menu-user-operations",
    name: "Kullanıcı İşlemleri",
    icon: "/media/svg/topMenu/user.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "top-menu-counselling-operations": {
    id: "top-menu-counselling-operations",
    name: "Rehberlik İşlemleri",
    icon: "/media/svg/topMenu/counselling.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "top-menu-course-operations": {
    id: "top-menu-course-operations",
    name: "Ders İşlemleri",
    icon: "/media/svg/topMenu/course.svg",
    color: "#90CAF9",
    subMenuList: []
  },
  "top-menu-message-operations": {
    id: "top-menu-message-operations",
    name: "Mesajlar",
    icon: "/media/svg/icons/message.svg",
    color: "#90CAF9",
    subMenuList: []
  }
};

export { AdminSideTopMenuEnum };
