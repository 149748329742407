import React, { createContext, useMemo } from "react";
import { getLanguageConfig } from "./LanguageFunctions";

export const LanguageContext = createContext();

function LanguageProvider({ children }) {
  const lang = useMemo(getLanguageConfig, []);

  return <LanguageContext.Provider value={lang}>{children}</LanguageContext.Provider>;
}
export default LanguageProvider;
